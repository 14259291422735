import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/scss/app.scss"
import axios from "axios"

import ReCAPTCHA from "react-google-recaptcha"
import { navigate } from "@reach/router"
class VSM extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: "",
      name: null,
      email: null,
      phone: null,
      state: null,
      city: null,
      subscribed: false,
      captcha: false,
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onClickHandler = this.onClickHandler.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.captchaOnSuccess = this.captchaOnSuccess.bind(this)
  }
  onChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
    })
  }
  captchaOnSuccess() {
    this.setState({ captcha: !this.state.captcha })
  }
  onClickHandler = event => {
    if (this.state.captcha) {
      event.preventDefault()
      const data = new FormData()
      data.append("Name", this.state.name)
      data.append("Email", this.state.email)
      data.append("Phone", this.state.phone)
      data.append("State/Province", this.state.state)
      data.append("City", this.state.city)
      data.append("Subscribed", this.state.subscribed)
      data.append("Image", this.state.selectedFile)
      data.append("_next", "/mobile/thank-you.html")
      data.append("_cc", "drkaloti@thesmilestylist.ca,press@thesmilestylist.ca")
      axios
        .post("https://formspree.io/mogvlkkm", data, {
          // receive two    parameter endpoint url ,form data
        })
        .then(res => {
          navigate("/thank-you")
        })
    } else {
      event.preventDefault()
      document.querySelector(".captcha").scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      })
      document.querySelector(".captcha").classList += " captcha_highlight"
    }
  }
  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Virtual Smile Makeover Toronto – Cosmetic Dentistry"
          description="Trying to picture yourself with the smile you have always dreamed of? Just send us a high-res full-face image of your smile and receive your virtual smile makeover."
          keywords="Smile makeover Toronto, Cosmetic Dentistry"
        />
        <main className="site-content" role="main">
          <section id="main-top-img" className="smile-makeover hidden-xs">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 sec-title wow animated fadeInDown">
                  <h1>Virtual Smile Makeover</h1>
                  <h2>See what your best smile could look like!</h2>
                </div>
              </div>
            </div>
          </section>
          <section className="makeover">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="vsm__title">
                    Wondering what a life changing makeover would look like?
                  </h2>
                  <p className="vsm__desc">
                    Simply submit a <b>high resolution, full face picture</b> of
                    you <b>smiling and showing your teeth</b> and within{" "}
                    <b>48 hours</b> you’ll receive your very own virtual smile
                    makeover.
                  </p>
                </div>
              </div>
              <div className="row vsm__images">
                <div className="col-md-6">
                  <img
                    src={require("../images/desktop/vsm-1.jpg")}
                    alt="Smile Makeover"
                  />
                </div>
                <div className="col-md-6">
                  <img
                    src={require("../images/desktop/vsm-2.jpg")}
                    alt="Dental Treatment"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <form method="post" action="https://formspree.io/mogvlkkm">
                    <input
                      type="hidden"
                      name="_next"
                      value="/mobile/thank-you.html"
                    />
                    <input
                      type="hidden"
                      name="_cc"
                      value="drkaloti@thesmilestylist.ca,press@thesmilestylist.ca"
                    />
                    <h3 className="vsm__form__title">Please Enter Your</h3>
                    <div className="form-group">
                      <input
                        onChange={this.handleInputChange}
                        type="text"
                        id="txt_name"
                        name="name"
                        placeholder="name"
                        className="form-control form_black"
                        tabIndex="1"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <input
                        onChange={this.handleInputChange}
                        type="email"
                        id="txt_email"
                        name="email"
                        placeholder="email"
                        className="form-control form_black"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <input
                        onChange={this.handleInputChange}
                        type="tel"
                        id="txt_phone"
                        name="phone"
                        placeholder="number"
                        className="form-control form_black"
                      />
                    </div>

                    <div className="form-group">
                      <select
                        onChange={this.handleInputChange}
                        id="ddl_states"
                        name="state"
                        className="form-control form_black form__field-left"
                        tabIndex="4"
                      >
                        <option>state/province</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DC">District of Columbia</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NU">Nunavut</option>
                        <option value="YT">Yukon</option>
                      </select>
                      <input
                        onChange={this.handleInputChange}
                        type="text"
                        id="txt_city"
                        name="city"
                        placeholder="city"
                        className="form-control form_black form__field-right"
                      />
                    </div>

                    <div className="form-group form__upload-cont">
                      <input
                        type="file"
                        name="Image"
                        id="FileUpload1"
                        className="form__upload"
                        onChange={this.onChangeHandler}
                      />
                    </div>

                    <ReCAPTCHA
                      className="captcha"
                      sitekey="6Ldyns0UAAAAAEsOzuby5eySa1QgoPCUvtWN0vsp"
                      onChange={this.captchaOnSuccess}
                    />

                    <input
                      onClick={this.onClickHandler}
                      type="submit"
                      value="Upload Your Smile"
                      className="btn btn-red btn-danger"
                    />

                    <div className="form-group">
                      <div className="form__checkbox">
                        <div className="pretty p-svg p-plain p-bigger p-jelly">
                          <input
                            onChange={this.handleInputChange}
                            name="subscribed"
                            type="checkbox"
                            id="btn_signup"
                            defaultChecked="true"
                            className="d-none"
                          />
                          <div className="state">
                            <span className="svg d-inblock">
                              <i>
                                <input
                                  type="checkbox"
                                  name="newsletter_signup w-20"
                                />
                              </i>
                            </span>
                            <label>
                              Sign up to the Smile Stylist newsletter and
                              receive the latest news, specials & updates.{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <script src="/inc/scripts/vsm-sub.js"></script>
          <script>feather.replace()</script>
        </main>
      </Layout>
    )
  }
}

export default VSM
